.Error-page-main-container{
    width: 100vw;
    height: 100vh;
    margin: auto;
}

.Error-page-error-card{
    width: 35vw;
    height: 20vw;
    border-radius: 1vw;
    background-color: whitesmoke;
    margin: auto;
    margin-top: 7vw;
    flex-direction: column;
}


.error-page-primary-text{
    font-size: 1.5vw;
    margin-bottom: 1vw;
    font-weight: 600;
    margin-left: 2vw;
}
.error-page-secondary-text{
    font-size: 1vw;
    font-weight: 500;
}