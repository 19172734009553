.navbar-main-container{
    width: 100vw;
    height: 4vw;
    background-color: #3C8DBC;
    position: fixed;
    z-index: 3;
    display: flex;
    align-items: center;
}

.tercept-logo-container{
    width: 10vw;
    height: 3vw;
    /* background-color: beige; */
    margin-left: 2vw;
    cursor: pointer;
}

.tercept-logo{
    width: 10vw;
    height: 2vw;
}