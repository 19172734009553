.login-main-container{
    width: 30vw;
    background-color: whitesmoke;
    margin: auto;
    margin-top: 7vw;
    margin-bottom: 3vw;
    flex-direction: column;
    box-shadow: -1px -1px 6px #fff, 1px 2px 6px rgb(0 0 0 / 15%);
    border-radius: .5vw; 
    padding-bottom: 2vw;  
}

.patner-logo-img{
    width: 4vw;
    height: 4vw;
    border-radius: 50%;
    margin-bottom: 1vw;
    margin-top: 1vw;
}

.check-box-container{
    height: 2vw;
    padding-left: 0.5vw;
    margin-bottom: 1vw;
}

.google-btn-container{
    width: 10vw;
    height: 1vw;
    margin: auto;
}

.sign-in-btn-logo{
    width: 2vw;
    height: 2vw;
    border-radius: .4vw;
    padding: .2vw;
}

.login-main-container>.MuiTableContainer-root>.MuiTable-root>.MuiTableHead-root{
    height: 1vw;
}

.modal-text-container{
    width: 100%;
    margin:1vw  auto;
    flex-direction: column;
}

.primary-text{
    font-size: 1.3vw;
    font-weight: 600;
    margin-bottom: .5vw;
    text-align: center;
}

.secondary-text{
    margin:0vw 1vw ;
    font-size: 1vw;
    font-weight: 400;
    margin-bottom: .5vw;
}

.skeleton-main-container{
    width: 35vw;
    margin: 2vw auto;
    margin-top: 6vw;
    /* background-color: aquamarine; */
}


.google-btn-container{
    width: 20vw;
    height: 4.5vw;
    /* background-color: darkred; */
}

















#customBtn {
    display: inline-block;
    background: white;
    color: #444;
    width: 190px;
    border-radius: 5px;
    border: thin solid #888;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;
  }
  #customBtn:hover {
    cursor: pointer;
  }
  span.label {
    font-family: serif;
    font-weight: normal;
  }
  span.icon {
    background: url('../../images/googleLogo.jpg') transparent 5px 50% no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
  }
  span.buttonText {
    display: inline-block;
    vertical-align: middle;
    padding-left: 42px;
    padding-right: 42px;
    font-size: 14px;
    font-weight: bold;
    /* Use the Roboto font that is loaded in the <head> */
    font-family: 'Roboto', sans-serif;
  }