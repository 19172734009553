.no-data-main-container{
    width: 35vw;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: whitesmoke;
    border-radius: 1vw;
    margin-top: 6vw;
}

.no-data-google-ads-logo-container{
    width: 35vw;
    margin: 1vw 0;
}


.google-ads-img{
    width: 4vw;
    height: 4vw;
    border-radius: 50%;
    margin-right: 1vw;
}


.no-data-found-primary{
    margin-bottom: 1vw;
    font-weight: 500;
}

.no-data-found-secondary{
    margin-bottom: 1vw;
}