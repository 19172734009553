.data-table-main-container{
    width: 35vw;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    margin: 2vw auto;
    margin-top: 6vw;
    border-radius:1vw ;
}

.customer-data-container{
    width: 96vw;
    margin: auto;
    margin-top: 2vw;
}

.customer-data-table-header{
    width: 96vw;
    height: 3vw;
    color: white;
    background-color: #CAD3C8;
    display: flex;
}

.customer-name-container{
    width: 80%;
    height: 100%;
    border-right: solid 3px white ;
    text-align: center;
    font-size: 1.2vw;
    font-weight: 600;
    line-height: 100%;
    border-bottom: solid 3px white ;
}

.customer-checkbox-container{
    width: 20%;
    height: 100%;
    text-align: center;
    font-size: 1.2vw;
    font-weight: 600;
    line-height: 100%;
    border-bottom: solid 3px white ;
}

.customer-data-main-container{
    width: 96vw;
    height: 3vw;
    display: flex;
    background-color: #b2bec377;
    color: black;
}

.customer-checkbox-container>.MuiFormControlLabel-root{
    margin-left: .5vw;
    margin-right: 0;
}

.Submit-btn-container{
    width: 8vw;
    height: 2vw;
    margin: auto;
    margin-top: 1vw;
    margin-bottom: 1vw;

}

.google-ads-logo{
    width: 4vw;
    height: 4vw;
    border-radius: 50%;
    margin: auto;
}

.google-ads-logo-container{
    width: 12vw;
    height: 3.5vw;
    margin: auto    ;
    margin-top: 2vw;
}


.customer-data-table-main-container{
    width: 35vw;
    margin-top: 2vw;
    /* background-color: aquamarine; */
}

.data-table-header{
    width: 100%;
    height: 2.5vw;
    background-color:#3C8DBC;
    display: flex;
    font-size: 1vw;
}

.data-table-header-primary{
    width: 80%;
    height: 100%;
    border-bottom: 1px solid white;
    border-right: 1px solid white ;
    color: white;
}

.data-table-header-secondary{
    width: 20%;
    height: 100%;
    border-bottom: 1px solid white;
    border-right: 1px solid white ;
    color: white;
}
.data-table-content-primary{
    width: 80%;
    height: 100%;
    border-right: 1px solid white ;
    color: black;
    font-size: 1vw;
}

.data-table-content-secondary{
    width: 20%;
    height: 100%;
    border-right: 1px solid white ;
    color: white;
}


.data-table-content-row{
    width: 100%;
    height: 2vw;
    background-color:transparent    ;
    display: flex;
    border-bottom: 1px solid rgba(128, 128, 128, 0.454);

}